export default {
  "info": "questionnaire registre vasculites",
  "children": [
    {
      "id": "21",
      "label": "Vasculite",
      "index": 63,
      "children": [
        {
          "id": "22",
          "label": "Manifestations cliniques",
          "index": 63,
          "children": [
            {
              "id": "23",
              "label": "Général",
              "index": 63,
              "children": [
                {
                  "id": "6400",
                  "index": 64,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Général",
                  "name": "Fièvre plus de 38,0",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "6500",
                  "index": 65,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Général",
                  "name": "Perte de poids plus de 2 kg",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "6600",
                  "index": 66,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Général",
                  "name": "Baisse de l’état général",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "24",
              "label": "Musculosquelettique",
              "index": 67,
              "children": [
                {
                  "id": "6700",
                  "index": 67,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Musculosquelettique",
                  "name": "Arthralgies",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "6800",
                  "index": 68,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Musculosquelettique",
                  "name": "Myalgies",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "6900",
                  "index": 69,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Musculosquelettique",
                  "name": "Polymyalgia rhumatica",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7000",
                  "index": 70,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Musculosquelettique",
                  "name": "Gonflement articulaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7100",
                  "index": 71,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Musculosquelettique",
                  "name": "Myosite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7200",
                  "index": 72,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Musculosquelettique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "25",
              "label": "Peau",
              "index": 73,
              "children": [
                {
                  "id": "7300",
                  "index": 73,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Purpura",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7400",
                  "index": 74,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Vésicule",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7500",
                  "index": 75,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Bulle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7600",
                  "index": 76,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Pustule",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7700",
                  "index": 77,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Ischémie digitale /gangrène",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7800",
                  "index": 78,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Erythème noueux",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "7900",
                  "index": 79,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Nodule cutané",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8000",
                  "index": 80,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Hémorragie en flamèche",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8100",
                  "index": 81,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Livedo",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8200",
                  "index": 82,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Ulcère",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8300",
                  "index": 83,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Pyoderma gangrenosum",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8400",
                  "index": 84,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Peau",
                  "name": "Autres",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "26",
              "label": "Ophtalomologique",
              "index": 85,
              "children": [
                {
                  "id": "8500",
                  "index": 85,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Baisse/perte de la vue/embrouillement visuel",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8600",
                  "index": 86,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Diplopie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8700",
                  "index": 87,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Douleur",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8800",
                  "index": 88,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Conjonctivite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "8900",
                  "index": 89,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Uvéite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9000",
                  "index": 90,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Kératite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9100",
                  "index": 91,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Sclérite/épisclérite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9200",
                  "index": 92,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Neuropathie optique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9300",
                  "index": 93,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Rétinopathie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9400",
                  "index": 94,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Proptose",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9500",
                  "index": 95,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Ophtalomologique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "27",
              "label": "ORL",
              "index": 96,
              "children": [
                {
                  "id": "9600",
                  "index": 96,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Hypoacousie : conduction",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9700",
                  "index": 97,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Hypoacousie : neurosensorielle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9800",
                  "index": 98,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Vertiges",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "9900",
                  "index": 99,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Acouphène",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10000",
                  "index": 100,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Congestion nasale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10100",
                  "index": 101,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Atteinte sinusale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10200",
                  "index": 102,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Ecoulement",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10300",
                  "index": 103,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Epistaxis",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10400",
                  "index": 104,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Croûtes",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10500",
                  "index": 105,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Chondrite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10600",
                  "index": 106,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Dysphonie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10700",
                  "index": 107,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Claudication mâchoire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10800",
                  "index": 108,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Sténose sous-glottique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "10900",
                  "index": 109,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Inflammation annexes (glandes)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11000",
                  "index": 110,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "ORL",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "28",
              "label": "Pulmonaire",
              "index": 111,
              "children": [
                {
                  "id": "11100",
                  "index": 111,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Toux",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11200",
                  "index": 112,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Dyspnée",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11300",
                  "index": 113,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Douleur pleurétique / effusion pleurale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11400",
                  "index": 114,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Asthme /sibilances",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11500",
                  "index": 115,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Hémoptysie importante",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11600",
                  "index": 116,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Nodules pulmonaires",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11700",
                  "index": 117,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Hémorragie alvéolaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11800",
                  "index": 118,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Infiltrats",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "11900",
                  "index": 119,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Anomalies fonctions respiratoires",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12000",
                  "index": 120,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Atteinte endobronchique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12100",
                  "index": 121,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Insuffisance respiratoire requérant intubation",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12200",
                  "index": 122,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Pulmonaire",
                  "name": "Autres",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "29",
              "label": "Cardiovasculaire",
              "index": 123,
              "children": [
                {
                  "id": "12300",
                  "index": 123,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Cardiovasculaire",
                  "name": "Douleur cardiaque ischémique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12400",
                  "index": 124,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Cardiovasculaire",
                  "name": "Insuffisance cardiaque",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12500",
                  "index": 125,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Cardiovasculaire",
                  "name": "Atteinte vasculitique macrovasculaire périphérique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12600",
                  "index": 126,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Cardiovasculaire",
                  "name": "Valvulopathie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12700",
                  "index": 127,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Cardiovasculaire",
                  "name": "Péricardite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "12800",
                  "index": 128,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Cardiovasculaire",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "30",
              "label": "Gastrointestinal",
              "index": 129,
              "children": [
                {
                  "id": "12900",
                  "index": 129,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Hématémèse",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13000",
                  "index": 130,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Rectorragie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13100",
                  "index": 131,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Melena",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13200",
                  "index": 132,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Ulcères buccaux",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13300",
                  "index": 133,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Perforation",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13400",
                  "index": 134,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Pancréatite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13500",
                  "index": 135,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Douleur sur ischémie mésentérique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13600",
                  "index": 136,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Diarrhée",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13700",
                  "index": 137,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Péritonite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "13800",
                  "index": 138,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Gastrointestinal",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "31",
              "label": "Génitourinaire",
              "index": 139,
              "children": [
                {
                  "id": "13900",
                  "index": 139,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Protéinurie supérieure à 1+ ou P:C plus de 0,2",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Quantité",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "plus de 0,2g",
                        "plus de 0,5g",
                        "plus de 1g",
                        "plus de 3g"
                      ],
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Quantite"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14000",
                  "index": 140,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Hématurie modérée ou 10 GR/champ ou plus",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14100",
                  "index": 141,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Élévation de créatinine plus de 30% / diminution du DFG plus 25%",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Élévation créatinine > 30%",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "newLine": true,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Elevation creatinine > 30%"
                    },
                    {
                      "label": "Créatinine (nombre en umol/L)",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "d4,enable,boolean,true",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Creatinine (nombre en umol/L)"
                    },
                    {
                      "label": "Diminution du DFG > 25%",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "newLine": true,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Diminution du DFG > 25%"
                    },
                    {
                      "label": "DFG estimé (cc/min)",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d7",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d6,enable,boolean,true",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "DFG estime (cc/min)"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14200",
                  "index": 142,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Cylindres hématiques",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14300",
                  "index": 143,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Dialyse",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14400",
                  "index": 144,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Hypertension (diastolique supérieure à95)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14500",
                  "index": 145,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Ulcères génitaux",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14600",
                  "index": 146,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Génitourinaire",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "32",
              "label": "Métabolique",
              "index": 147,
              "children": [
                {
                  "id": "14700",
                  "index": 147,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Métabolique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "33",
              "label": "Neurologique",
              "index": 148,
              "children": [
                {
                  "id": "14800",
                  "index": 148,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Atteinte méningée",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "14900",
                  "index": 149,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Vasculite cérébrale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15000",
                  "index": 150,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Céphalée",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15100",
                  "index": 151,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Thrombose sinus veineux Cérébrite/encéphalopathie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15200",
                  "index": 152,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Accident vasculaire cérébral",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15300",
                  "index": 153,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Confusion organique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15400",
                  "index": 154,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Myélopathie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15500",
                  "index": 155,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Polyneuropathie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15600",
                  "index": 156,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Mononévrite multiplex",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15700",
                  "index": 157,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Convulsions",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15800",
                  "index": 158,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Neuropathie crânienne",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "15900",
                  "index": 159,
                  "tab": "Vasculite",
                  "category": "Manifestations cliniques",
                  "subCategory": "Neurologique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>=",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "1",
      "label": "Antécédents",
      "index": 1,
      "children": [
        {
          "id": "2",
          "label": "Antécédents",
          "index": 1,
          "children": [
            {
              "id": "3",
              "label": "Cardiovasculaire",
              "index": 1,
              "children": [
                {
                  "id": "100",
                  "index": 1,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "MCAS",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "200",
                  "index": 2,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "Insuffisance cardiaque",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "300",
                  "index": 3,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "MVAS",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "400",
                  "index": 4,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "Hypertension artérielle ",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "500",
                  "index": 5,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "Dyslipidémie ",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "600",
                  "index": 6,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "Revascularisation",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "700",
                  "index": 7,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "ACV / ICT",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "800",
                  "index": 8,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Cardiovasculaire",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie cardiovasculaire",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie cardiovasculaire"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "4",
              "label": "Pulmonaire",
              "index": 9,
              "children": [
                {
                  "id": "900",
                  "index": 9,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Pulmonaire",
                  "name": "MPOC",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1000",
                  "index": 10,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Pulmonaire",
                  "name": "Asthme",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1100",
                  "index": 11,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Pulmonaire",
                  "name": "Bronchiectasies",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1200",
                  "index": 12,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Pulmonaire",
                  "name": "Pneumopathie interstitielle",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1300",
                  "index": 13,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Pulmonaire",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie pulmonaire",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie pulmonaire"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "5",
              "label": "Gastrointestinal et hépatobiliaire",
              "index": 14,
              "children": [
                {
                  "id": "1400",
                  "index": 14,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Gastrointestinal et hépatobiliaire",
                  "name": "Ulcère peptique",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1500",
                  "index": 15,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Gastrointestinal et hépatobiliaire",
                  "name": "Hépatite infectieuse",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1600",
                  "index": 16,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Gastrointestinal et hépatobiliaire",
                  "name": "Pancréatite",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1700",
                  "index": 17,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Gastrointestinal et hépatobiliaire",
                  "name": "Hémorragie digestive",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1800",
                  "index": 18,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Gastrointestinal et hépatobiliaire",
                  "name": "Diverticulose /diverticulite",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "1900",
                  "index": 19,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Gastrointestinal et hépatobiliaire",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie gastrointestinal et hépatobiliaire",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie gastrointestinal et hepatobiliaire"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "6",
              "label": "Rénal",
              "index": 20,
              "children": [
                {
                  "id": "2000",
                  "index": 20,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Rénal",
                  "name": "insuffisance rénale ",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Stade",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "1",
                        "2",
                        "3A",
                        "3B",
                        "4",
                        "5"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Stade"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "2100",
                  "index": 21,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Rénal",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie rénal",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie renal"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "7",
              "label": "Endocrinien",
              "index": 22,
              "children": [
                {
                  "id": "2200",
                  "index": 22,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Endocrinien",
                  "name": "Hypothyroïdie",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "2300",
                  "index": 23,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Endocrinien",
                  "name": "HyperthyroÏdie",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "2400",
                  "index": 24,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Endocrinien",
                  "name": "Diabète",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "2500",
                  "index": 25,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Endocrinien",
                  "name": "Hyperparathyroïdie",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "2600",
                  "index": 26,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Endocrinien",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie endocrinienne",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie endocrinienne"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "8",
              "label": "Neurologique",
              "index": 27,
              "children": [
                {
                  "id": "2700",
                  "index": 27,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Neurologique",
                  "name": "Migraine",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "2800",
                  "index": 28,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Neurologique",
                  "name": "Accident vasculaire cérébral",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "2900",
                  "index": 29,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Neurologique",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie neurologique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie neurologique"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "9",
              "label": "Hématologique",
              "index": 30,
              "children": [
                {
                  "id": "3000",
                  "index": 30,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Hématologique",
                  "name": "Maladie de Hodgkin",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "3100",
                  "index": 31,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Hématologique",
                  "name": "Lymphome non Hodgkinien",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "3200",
                  "index": 32,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Hématologique",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie hématologique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie hematologique"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "10",
              "label": "Peau",
              "index": 33,
              "children": [
                {
                  "id": "3300",
                  "index": 33,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Peau",
                  "name": "Psoriasis",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "3400",
                  "index": 34,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Peau",
                  "name": "Néoplasie",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "3500",
                  "index": 35,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Peau",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez la maladie de la peau",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez la maladie de la peau"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "11",
              "label": "Problème musculosquelettique",
              "index": 36,
              "children": [
                {
                  "id": "3600",
                  "index": 36,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Problème musculosquelettique",
                  "name": "Arthropathie inflammatoire",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "3700",
                  "index": 37,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Problème musculosquelettique",
                  "name": "Ostéoporose",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Fracturaire ou non",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "fracturaire",
                        "non fracturaire"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Fracturaire ou non"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "3800",
                  "index": 38,
                  "tab": "Antécédents",
                  "category": "Antécédents",
                  "subCategory": "Problème musculosquelettique",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2V,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Date début des symptômes",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date debut des symptomes"
                    },
                    {
                      "label": "Date rémission",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date remission"
                    },
                    {
                      "label": "Spécifiez le problème musculosquelettique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez le probleme musculosquelettique"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": "12",
          "label": "Antécédents familiaux",
          "index": 39,
          "children": [
            {
              "id": "13",
              "label": "Général",
              "index": 39,
              "children": [
                {
                  "id": "3900",
                  "index": 39,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "Maladies pulmonaires",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Nom de la maladie pulmonaires",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la maladie pulmonaires"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4000",
                  "index": 40,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "MCAS",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4100",
                  "index": 41,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "MVAS",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4200",
                  "index": 42,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "Hypertension artérielle ",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4300",
                  "index": 43,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "Diabète",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4400",
                  "index": 44,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "Dyslipidémie ",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4500",
                  "index": 45,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "Psoriasis",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4600",
                  "index": 46,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Général",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Nom de la maladie",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la maladie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "14",
              "label": "Maladie immunologiques",
              "index": 47,
              "children": [
                {
                  "id": "4700",
                  "index": 47,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Maladie immunologiques",
                  "name": "Thyroïde",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4800",
                  "index": 48,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Maladie immunologiques",
                  "name": "Arthropathies inflammatoires",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "4900",
                  "index": 49,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Maladie immunologiques",
                  "name": "Collagénoses",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "5000",
                  "index": 50,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Maladie immunologiques",
                  "name": "Vasculites",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "5100",
                  "index": 51,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Maladie immunologiques",
                  "name": "Maladies intestinales inflammatoires",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "5200",
                  "index": 52,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Maladie immunologiques",
                  "name": "Maladies hématologiques immunes (leucopénie, anémie, thrombopénie)",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "5300",
                  "index": 53,
                  "tab": "Antécédents",
                  "category": "Antécédents familiaux",
                  "subCategory": "Maladie immunologiques",
                  "name": "Autre",
                  "labelItem": "Ajouter un autre cas",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Lien parental",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Mère",
                        "Père",
                        "Frère",
                        "Soeur",
                        "Grand-mère",
                        "Grand-père",
                        "Autre"
                      ],
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Lien parental"
                    },
                    {
                      "label": "Nom de la maladie immunologiques",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la maladie immunologiques"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "15",
      "label": "Données démographiques",
      "index": 54,
      "children": [
        {
          "id": "16",
          "label": "Données démographiques",
          "index": 54,
          "children": [
            {
              "id": "17",
              "label": "Données démographiques",
              "index": 54,
              "children": [
                {
                  "id": "5400",
                  "index": 54,
                  "tab": "Données démographiques",
                  "category": "Données démographiques",
                  "subCategory": "Données démographiques",
                  "name": "Emploi",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de début",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date de fin",
                      "type": "dateF",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date de fin"
                    },
                    {
                      "label": "Emploi",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Emploi"
                    }
                  ]
                },
                {
                  "id": "5500",
                  "index": 55,
                  "tab": "Données démographiques",
                  "category": "Données démographiques",
                  "subCategory": "Données démographiques",
                  "name": "Ethnie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "",
                  "isMultiple": false,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Ethnie",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "caucasien",
                        "noir",
                        "asiatique",
                        "hispanique",
                        "premières nations",
                        "autres"
                      ],
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Ethnie"
                    }
                  ]
                },
                {
                  "id": "5600",
                  "index": 56,
                  "tab": "Données démographiques",
                  "category": "Données démographiques",
                  "subCategory": "Données démographiques",
                  "name": "Code Postal",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Code postal (3 premiers caractères)",
                      "type": "text",
                      "unit": "",
                      "choice": "CODEPOSTAL",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Code postal (3 premiers caracteres)"
                    },
                    {
                      "label": "Début de résidence",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Debut de residence"
                    },
                    {
                      "label": "Date de fin de résidence",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date de fin de residence"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "18",
      "label": "Habitudes de vie",
      "index": 57,
      "children": [
        {
          "id": "19",
          "label": "Habitudes de vie",
          "index": 57,
          "children": [
            {
              "id": "20",
              "label": "Habitudes de vie",
              "index": 57,
              "children": [
                {
                  "id": "5700",
                  "index": 57,
                  "tab": "Habitudes de vie",
                  "category": "Habitudes de vie",
                  "subCategory": "Habitudes de vie",
                  "name": "Tabagisme",
                  "labelItem": "Ajouter une rechute",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Paquets/semaine",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Paquets/semaine"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "5800",
                  "index": 58,
                  "tab": "Habitudes de vie",
                  "category": "Habitudes de vie",
                  "subCategory": "Habitudes de vie",
                  "name": "Alcool",
                  "labelItem": "Ajouter une rechute",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Consommation/semaine",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Consommation/semaine"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "5900",
                  "index": 59,
                  "tab": "Habitudes de vie",
                  "category": "Habitudes de vie",
                  "subCategory": "Habitudes de vie",
                  "name": "Cannabis",
                  "labelItem": "Ajouter une rechute",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Consommation/semaine",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Consommation/semaine"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "6000",
                  "index": 60,
                  "tab": "Habitudes de vie",
                  "category": "Habitudes de vie",
                  "subCategory": "Habitudes de vie",
                  "name": "Crack",
                  "labelItem": "Ajouter une rechute",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Consommation/semaine",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Consommation/semaine"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "6100",
                  "index": 61,
                  "tab": "Habitudes de vie",
                  "category": "Habitudes de vie",
                  "subCategory": "Habitudes de vie",
                  "name": "Cocaïne",
                  "labelItem": "Ajouter une rechute",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Consommation/semaine",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Consommation/semaine"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "6200",
                  "index": 62,
                  "tab": "Habitudes de vie",
                  "category": "Habitudes de vie",
                  "subCategory": "Habitudes de vie",
                  "name": "Autre",
                  "labelItem": "Ajouter une rechute",
                  "labelVisit": "Ajouter une note",
                  "orderByField": "d1",
                  "isMultiple": true,
                  "prefixForMultiple": "récidive",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d1,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d1",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Spécifiez le produit",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifiez le produit"
                    },
                    {
                      "label": "Consommation/semaine",
                      "type": "nombre",
                      "unit": "nombre",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Consommation/semaine"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "34",
      "label": "Médication, Traitements, Vaccins",
      "index": 160,
      "children": [
        {
          "id": "35",
          "label": "Médication, Traitements, Vaccins",
          "index": 160,
          "children": [
            {
              "id": "36",
              "label": "Immunomodulateurs",
              "index": 160,
              "children": [
                {
                  "id": "16000",
                  "index": 160,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Corticoïdes  (équivalent prednisone ou prednisolone)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16100",
                  "index": 161,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Cyclophosphamide",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16200",
                  "index": 162,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Azathioprine",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16300",
                  "index": 163,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Methotrexate",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/semaine",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16400",
                  "index": 164,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Mycophenolate mofetil",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16500",
                  "index": 165,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Leflunomide",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16600",
                  "index": 166,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Rituximab",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "choix_multiples",
                      "unit": "mg/cycle",
                      "choice": [
                        "375 mg / m2 X 4",
                        "500 mg J1 et J15",
                        "500 mg",
                        "1000 mg J1 et J15",
                        "1000 mg",
                        "autre"
                      ],
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Fréquence d’administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "chaque mois",
                        "aux 6 mois, aux années",
                        "autre"
                      ],
                      "key": "d7",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Frequence d’administration"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16700",
                  "index": 167,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Abatacept",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Fréquence d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "chaque semaine",
                        "chaque mois",
                        "autre"
                      ],
                      "key": "d7",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Frequence d'administration"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16800",
                  "index": 168,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Tocilizumab",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Fréquence d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "par semaine",
                        "par mois"
                      ],
                      "key": "d7",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Frequence d'administration"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "16900",
                  "index": 169,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Ustekinumab",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg par 3 mois",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17000",
                  "index": 170,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Inhibiteur de JaK",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17100",
                  "index": 171,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "IVIG",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "g/",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Fréquence d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "semaine",
                        "mois",
                        "autre"
                      ],
                      "key": "d7",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Frequence d'administration"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17200",
                  "index": 172,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Immunomodulateurs",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "37",
              "label": "Cardiovasculaire",
              "index": 173,
              "children": [
                {
                  "id": "17300",
                  "index": 173,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Anticoagulants",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17400",
                  "index": 174,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Antiplaquettaires",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17500",
                  "index": 175,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Statines",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17600",
                  "index": 176,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "IECA",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17700",
                  "index": 177,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "ARA",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17800",
                  "index": 178,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Bloqueurs canaux calciques",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "17900",
                  "index": 179,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Beta bloquants",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "18000",
                  "index": 180,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Diurétiques",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "18100",
                  "index": 181,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Hypotenseurs autres",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "18200",
                  "index": 182,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Cardiovasculaire",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "38",
              "label": "Respiratoire",
              "index": 183,
              "children": [
                {
                  "id": "18300",
                  "index": 183,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Respiratoire",
                  "name": "Stéroïdes inhalés",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "18400",
                  "index": 184,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Respiratoire",
                  "name": "Bronchodilatateurs",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "18500",
                  "index": 185,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Respiratoire",
                  "name": "Inhibiteurs de leukotriènes",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "18600",
                  "index": 186,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Respiratoire",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "39",
              "label": "Gastrointestinal",
              "index": 187,
              "children": [
                {
                  "id": "18700",
                  "index": 187,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Gastrointestinal",
                  "name": "Inhibiteurs de la pompe à protons",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "18800",
                  "index": 188,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Gastrointestinal",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "40",
              "label": "Endocrinien",
              "index": 189,
              "children": [
                {
                  "id": "18900",
                  "index": 189,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Endocrinien",
                  "name": "Insuline",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "unités/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19000",
                  "index": 190,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Endocrinien",
                  "name": "Hypoglycémiants oraux",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19100",
                  "index": 191,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Endocrinien",
                  "name": "Hormonothérapie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19200",
                  "index": 192,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Endocrinien",
                  "name": "Thyroxine",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19300",
                  "index": 193,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Endocrinien",
                  "name": "Anti thyroïdiens (ex. PTU)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19400",
                  "index": 194,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Endocrinien",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "41",
              "label": "Infectieux",
              "index": 195,
              "children": [
                {
                  "id": "19500",
                  "index": 195,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Infectieux",
                  "name": "Antibiotiques prophylactiques",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Catégorie",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "TMP/SMX",
                        "pentamidine",
                        "dapsone",
                        "atovaquone",
                        "acyclovir",
                        "valacyclovir",
                        "famcyclovir",
                        "autre"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Categorie"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d7",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19600",
                  "index": 196,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Infectieux",
                  "name": "Antibiothérapie (infection active)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19700",
                  "index": 197,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Infectieux",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "42",
              "label": "Ostéoporose",
              "index": 198,
              "children": [
                {
                  "id": "19800",
                  "index": 198,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Ostéoporose",
                  "name": "Vitamine D",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "19900",
                  "index": 199,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Ostéoporose",
                  "name": "Antirésorptifs",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Type",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bisphosphonates",
                        "denosumab",
                        "raloxifène",
                        "oestrogènes"
                      ],
                      "key": "d5",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d7",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "20000",
                  "index": 200,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Ostéoporose",
                  "name": "Agent anabolique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "20100",
                  "index": 201,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Ostéoporose",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "43",
              "label": "Produits naturels",
              "index": 202,
              "children": [
                {
                  "id": "20200",
                  "index": 202,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Produits naturels",
                  "name": "Produit naturel",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "i",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "20300",
                  "index": 203,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Produits naturels",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "i",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "44",
              "label": "Dermatologie",
              "index": 204,
              "children": [
                {
                  "id": "20400",
                  "index": 204,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Dermatologie",
                  "name": "StéroÏdes topiques",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "20500",
                  "index": 205,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Dermatologie",
                  "name": "Inhibiteurs de calcineurine topiques",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "20600",
                  "index": 206,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Dermatologie",
                  "name": "Dapsone",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "20700",
                  "index": 207,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Dermatologie",
                  "name": "Colchicine",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "20800",
                  "index": 208,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Dermatologie",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d3",
                  "isMultiple": true,
                  "prefixForMultiple": "médication",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de début",
                      "type": "dateD",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date de debut"
                    },
                    {
                      "label": "Date d'arrêt",
                      "type": "dateFF",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "d2,error,date,>",
                      "typeConstraint": "hintIfAbsent",
                      "hint": "(toujours actif)",
                      "depConstraint": "d2",
                      "fieldName": "Date d'arret"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Voie d'administration",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "PO",
                        "SC",
                        "IM",
                        "IV"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Voie d'administration"
                    },
                    {
                      "label": "Posologie",
                      "type": "nombre",
                      "unit": "mg/jour",
                      "choice": "",
                      "key": "d6",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Posologie"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "45",
              "label": "Vaccins",
              "index": 209,
              "children": [
                {
                  "id": "20900",
                  "index": 209,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Inluenza",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21000",
                  "index": 210,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Pneumocoque",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Type",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "prevnar 20",
                        "prevnar 13",
                        "pneumovax"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21100",
                  "index": 211,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "H influenzae",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21200",
                  "index": 212,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Hépatite A",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21300",
                  "index": 213,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Hépatite B",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21400",
                  "index": 214,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Herpes zoster (zona)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Type",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "zostavax",
                        "shingrix"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21500",
                  "index": 215,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Varicelle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21600",
                  "index": 216,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "N meningitidis",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21700",
                  "index": 217,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Papilloma virus",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21800",
                  "index": 218,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Fièvre jaune",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "21900",
                  "index": 219,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "DCT",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22000",
                  "index": 220,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "MMR",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22100",
                  "index": 221,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Tetanos",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22200",
                  "index": 222,
                  "tab": "Médication, Traitements, Vaccins",
                  "category": "Médication, Traitements, Vaccins",
                  "subCategory": "Vaccins",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date de vaccination",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Date de vaccination"
                    },
                    {
                      "label": "Nom du vaccin",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom du vaccin"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "46",
      "label": "Comorbidités",
      "index": 223,
      "children": [
        {
          "id": "47",
          "label": "Infection",
          "index": 223,
          "children": [
            {
              "id": "48",
              "label": "Cutané",
              "index": 223,
              "children": [
                {
                  "id": "22300",
                  "index": 223,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Cutané",
                  "name": "Cellulite, abcès, furonculose",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "49",
              "label": "ORLO",
              "index": 224,
              "children": [
                {
                  "id": "22400",
                  "index": 224,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "ORLO",
                  "name": "Oreilles",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22500",
                  "index": 225,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "ORLO",
                  "name": "Sinus",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22600",
                  "index": 226,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "ORLO",
                  "name": "Nez",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22700",
                  "index": 227,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "ORLO",
                  "name": "Oeil",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22800",
                  "index": 228,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "ORLO",
                  "name": "Pharynx/Larynx",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "22900",
                  "index": 229,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "ORLO",
                  "name": "Bouche / gencives",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "50",
              "label": "Respiratoire",
              "index": 230,
              "children": [
                {
                  "id": "23000",
                  "index": 230,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Respiratoire",
                  "name": "Voies respiratoires inférieures",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "23100",
                  "index": 231,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Respiratoire",
                  "name": "Bronchiectasies",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "23200",
                  "index": 232,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Respiratoire",
                  "name": "Cavité pleurale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "51",
              "label": "Cardiovasculaire",
              "index": 233,
              "children": [
                {
                  "id": "23300",
                  "index": 233,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Cardiovasculaire",
                  "name": "Endocarde",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "23400",
                  "index": 234,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Cardiovasculaire",
                  "name": "Myocarde",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "23500",
                  "index": 235,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Cardiovasculaire",
                  "name": "Péricarde",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "23600",
                  "index": 236,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Cardiovasculaire",
                  "name": "Vaisseaux sanguins",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "52",
              "label": "Digestif",
              "index": 237,
              "children": [
                {
                  "id": "23700",
                  "index": 237,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Digestif",
                  "name": "Oesophage/estomac",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "23800",
                  "index": 238,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Digestif",
                  "name": "Entérite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "23900",
                  "index": 239,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Digestif",
                  "name": "Colite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "24000",
                  "index": 240,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Digestif",
                  "name": "Foie et voies biliaires",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "53",
              "label": "Urogénital",
              "index": 241,
              "children": [
                {
                  "id": "24100",
                  "index": 241,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Urogénital",
                  "name": "Pyélonéphrite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "24200",
                  "index": 242,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Urogénital",
                  "name": "Organes génitaux",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "24300",
                  "index": 243,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Urogénital",
                  "name": "Cystite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "24400",
                  "index": 244,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Urogénital",
                  "name": "Uréthrite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "24500",
                  "index": 245,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Urogénital",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Nom de l'endroit anatomique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de l'endroit anatomique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "54",
              "label": "Système nerveux",
              "index": 246,
              "children": [
                {
                  "id": "24600",
                  "index": 246,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Système nerveux",
                  "name": "Cerveau",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "24700",
                  "index": 247,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Système nerveux",
                  "name": "Méninges",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "24800",
                  "index": 248,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Système nerveux",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Nom de l'endroit anatomique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de l'endroit anatomique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "55",
              "label": "Musculosquelettique",
              "index": 249,
              "children": [
                {
                  "id": "24900",
                  "index": 249,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Musculosquelettique",
                  "name": "Articulations",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "25000",
                  "index": 250,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Musculosquelettique",
                  "name": "Os",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "25100",
                  "index": 251,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Musculosquelettique",
                  "name": "Tendons",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "25200",
                  "index": 252,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Musculosquelettique",
                  "name": "Fascia",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "25300",
                  "index": 253,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Musculosquelettique",
                  "name": "Muscles",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "25400",
                  "index": 254,
                  "tab": "Comorbidités",
                  "category": "Infection",
                  "subCategory": "Musculosquelettique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Type de microorganismes",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "bactérien",
                        "mycobactérien",
                        "mycose",
                        "parasite",
                        "viral"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Type de microorganismes"
                    },
                    {
                      "label": "Nom de l'endroit anatomique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de l'endroit anatomique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": "56",
          "label": "Néoplasie",
          "index": 255,
          "children": [
            {
              "id": "57",
              "label": "Cutanée",
              "index": 255,
              "children": [
                {
                  "id": "25500",
                  "index": 255,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Cutanée",
                  "name": "Mélanome",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "25600",
                  "index": 256,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Cutanée",
                  "name": "Non Mélanome",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "58",
              "label": "Système nerveux central",
              "index": 257,
              "children": [
                {
                  "id": "25700",
                  "index": 257,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Système nerveux central",
                  "name": "Système nerveux central",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "59",
              "label": "ORLO",
              "index": 258,
              "children": [
                {
                  "id": "25800",
                  "index": 258,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "ORLO",
                  "name": "ORLO",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "60",
              "label": "Cardiovasculaire",
              "index": 259,
              "children": [
                {
                  "id": "25900",
                  "index": 259,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Cardiovasculaire",
                  "name": "Cardiovasculaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "61",
              "label": "Hématologique",
              "index": 260,
              "children": [
                {
                  "id": "26000",
                  "index": 260,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Hématologique",
                  "name": "Leucémie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "26100",
                  "index": 261,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Hématologique",
                  "name": "Lymphome",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "26200",
                  "index": 262,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Hématologique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de l'endroit anatomique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de l'endroit anatomique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "62",
              "label": "Respiratoire",
              "index": 263,
              "children": [
                {
                  "id": "26300",
                  "index": 263,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Respiratoire",
                  "name": "Poumon",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "26400",
                  "index": 264,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Respiratoire",
                  "name": "Plèvre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "26500",
                  "index": 265,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Respiratoire",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de l'endroit anatomique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de l'endroit anatomique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "63",
              "label": "Gastrointestinal",
              "index": 266,
              "children": [
                {
                  "id": "26600",
                  "index": 266,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Gastrointestinal",
                  "name": "Oesophage",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "26700",
                  "index": 267,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Gastrointestinal",
                  "name": "Estomac",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "26800",
                  "index": 268,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Gastrointestinal",
                  "name": "Intestin grêle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "26900",
                  "index": 269,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Gastrointestinal",
                  "name": "Colon",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "27000",
                  "index": 270,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Gastrointestinal",
                  "name": "Foie et voies biliaires",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "27100",
                  "index": 271,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Gastrointestinal",
                  "name": "Pancréas",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "27200",
                  "index": 272,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Gastrointestinal",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de l'endroit anatomique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de l'endroit anatomique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "64",
              "label": "Endocrinien",
              "index": 273,
              "children": [
                {
                  "id": "27300",
                  "index": 273,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Endocrinien",
                  "name": "Thyroïde",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "27400",
                  "index": 274,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Endocrinien",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de l'endroit anatomique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de l'endroit anatomique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "65",
              "label": "Sein",
              "index": 275,
              "children": [
                {
                  "id": "27500",
                  "index": 275,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Sein",
                  "name": "Sein",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "66",
              "label": "Génito urinaire",
              "index": 276,
              "children": [
                {
                  "id": "27600",
                  "index": 276,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Génito urinaire",
                  "name": "Spécifier",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "67",
              "label": "Autre",
              "index": 277,
              "children": [
                {
                  "id": "27700",
                  "index": 277,
                  "tab": "Comorbidités",
                  "category": "Néoplasie",
                  "subCategory": "Autre",
                  "name": "Spécifier",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom du type de néoplasie",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom du type de neoplasie"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": "68",
          "label": "Autres comorbidités",
          "index": 278,
          "children": [
            {
              "id": "69",
              "label": "Endocrino-métabolique",
              "index": 278,
              "children": [
                {
                  "id": "27800",
                  "index": 278,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Diabète",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "27900",
                  "index": 279,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Hypocorticisme",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28000",
                  "index": 280,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Hypercorticisme",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28100",
                  "index": 281,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Hypothyroïdie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28200",
                  "index": 282,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Hyperthyroïdie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28300",
                  "index": 283,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Dyslipidémie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28400",
                  "index": 284,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Hypogonadisme",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28500",
                  "index": 285,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Obésité",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28600",
                  "index": 286,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Endocrino-métabolique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités endocrino-métabolique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites endocrino-metabolique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "70",
              "label": "Os",
              "index": 287,
              "children": [
                {
                  "id": "28700",
                  "index": 287,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Os",
                  "name": "Ostéoporose",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Fracturaire ou non",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "fracturaire",
                        "non fracturaire"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Fracturaire ou non"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28800",
                  "index": 288,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Os",
                  "name": "Nécrose avasculaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "28900",
                  "index": 289,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Os",
                  "name": "Ostéomalacie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29000",
                  "index": 290,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Os",
                  "name": "Fracture atypique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29100",
                  "index": 291,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Os",
                  "name": "Fracture",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29200",
                  "index": 292,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Os",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités de l'os",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites de l'os"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "71",
              "label": "Cardiovasculaire",
              "index": 293,
              "children": [
                {
                  "id": "29300",
                  "index": 293,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "MCAS",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Infarctus du myocarde (oui / non)",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "oui",
                        "non"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Infarctus du myocarde (oui / non)"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29400",
                  "index": 294,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "MVAS",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29500",
                  "index": 295,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "Hypertension",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29600",
                  "index": 296,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "Insuffisance cardiaque",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "FEVG si disponible",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "FEVG si disponible"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29700",
                  "index": 297,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "Sténose de gros vaisseaux, asymétrie de pouls ou de pression",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Région Anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "carotide",
                        "tronc innominé",
                        "sous-clavière",
                        "artère axillaire",
                        "artère brachiale",
                        "artère ulnaire",
                        "artère radiale",
                        "artère digitale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "artère poplitée",
                        "artère péronière",
                        "artère tibiale postérieure",
                        "autre"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region Anatomique"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29800",
                  "index": 298,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "Anévrisme",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Région Anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "carotide",
                        "tronc innominé",
                        "sous-clavière",
                        "artère axillaire",
                        "artère brachiale",
                        "artère ulnaire",
                        "artère radiale",
                        "artère digitale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "artère poplitée",
                        "artère péronière",
                        "artère tibiale postérieure",
                        "autre"
                      ],
                      "key": "d5",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region Anatomique"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "29900",
                  "index": 299,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "Thrombose veineuse",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Préciser emplacement si connu",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Preciser emplacement si connu"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30000",
                  "index": 300,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Cardiovasculaire",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités cardiovasculaire",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites cardiovasculaire"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "72",
              "label": "ORLO",
              "index": 301,
              "children": [
                {
                  "id": "30100",
                  "index": 301,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "ORLO",
                  "name": "Cataractes",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30200",
                  "index": 302,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "ORLO",
                  "name": "Cécité",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30300",
                  "index": 303,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "ORLO",
                  "name": "Perte audition",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30400",
                  "index": 304,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "ORLO",
                  "name": "Perforation septum nasal ou autre structure os/cartilage",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30500",
                  "index": 305,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "ORLO",
                  "name": "Sténose sous glottique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30600",
                  "index": 306,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "ORLO",
                  "name": "Glaucome",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30700",
                  "index": 307,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "ORLO",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités ORLO",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites ORLO"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "73",
              "label": "Respiratoire",
              "index": 308,
              "children": [
                {
                  "id": "30800",
                  "index": 308,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Respiratoire",
                  "name": "Pneumopathie interstitielle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "30900",
                  "index": 309,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Respiratoire",
                  "name": "Embolie pulmonaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31000",
                  "index": 310,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Respiratoire",
                  "name": "MPOC",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31100",
                  "index": 311,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Respiratoire",
                  "name": "Hypertension pulmonaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31200",
                  "index": 312,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Respiratoire",
                  "name": "Dommage pleural (ex: fibrose)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31300",
                  "index": 313,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Respiratoire",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités respiratoire",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites respiratoire"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "74",
              "label": "neurologique",
              "index": 314,
              "children": [
                {
                  "id": "31400",
                  "index": 314,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "neurologique",
                  "name": "AVC / ICT",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31500",
                  "index": 315,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "neurologique",
                  "name": "Déclin cognitif",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31600",
                  "index": 316,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "neurologique",
                  "name": "Myélopathie chronique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31700",
                  "index": 317,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "neurologique",
                  "name": "Neuropathie périphérique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "31800",
                  "index": 318,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "neurologique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "75",
              "label": "Digestif",
              "index": 319,
              "children": [
                {
                  "id": "31900",
                  "index": 319,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Digestif",
                  "name": "Ulcus peptique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32000",
                  "index": 320,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Digestif",
                  "name": "Ischémie / infarctus mésentérique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32100",
                  "index": 321,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Digestif",
                  "name": "Perforation",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32200",
                  "index": 322,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Digestif",
                  "name": "Diverticulite",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32300",
                  "index": 323,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Digestif",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités digestive",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites digestive"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "76",
              "label": "Urogénital",
              "index": 324,
              "children": [
                {
                  "id": "32400",
                  "index": 324,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Urogénital",
                  "name": "Insuffisance rénale chronique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "1 (DFG Sup 90)",
                        "2 (DFG 61-89)",
                        "3a (DFG 46-60)",
                        "3b (DFG 31-44)",
                        "4 (DFG 16-29)",
                        "5 (DFG inf 15)"
                      ],
                      "key": "d3",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32500",
                  "index": 325,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Urogénital",
                  "name": "Dialyse chronique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32600",
                  "index": 326,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Urogénital",
                  "name": "Greffe rénale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32700",
                  "index": 327,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Urogénital",
                  "name": "Cystite hémorragique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "32800",
                  "index": 328,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Urogénital",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités urogénital",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites urogenital"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "77",
              "label": "Dermatologique",
              "index": 329,
              "children": [
                {
                  "id": "32900",
                  "index": 329,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Dermatologique",
                  "name": "Alopécie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "33000",
                  "index": 330,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Dermatologique",
                  "name": "Ulcère cutané",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "33100",
                  "index": 331,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Dermatologique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités dermatologique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites dermatologique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "78",
              "label": "Musculosqulettique",
              "index": 332,
              "children": [
                {
                  "id": "33200",
                  "index": 332,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Musculosqulettique",
                  "name": "Myopathie stéroïdienne",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "33300",
                  "index": 333,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Musculosqulettique",
                  "name": "Atrophie musculaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "79",
              "label": "Hématologique",
              "index": 334,
              "children": [
                {
                  "id": "33400",
                  "index": 334,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Hématologique",
                  "name": "Hypogammaglobulinémie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "33500",
                  "index": 335,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Hématologique",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités hématologique",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites hematologique"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "80",
              "label": "Nouvelle maladie autoimmune",
              "index": 336,
              "children": [
                {
                  "id": "33600",
                  "index": 336,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Nouvelle maladie autoimmune",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du diagnostique",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du diagnostique"
                    },
                    {
                      "label": "Nom de la comorbidités autoimmune",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Nom de la comorbidites autoimmune"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "81",
              "label": "Mortalité",
              "index": 337,
              "children": [
                {
                  "id": "33700",
                  "index": 337,
                  "tab": "Comorbidités",
                  "category": "Autres comorbidités",
                  "subCategory": "Mortalité",
                  "name": "Mort",
                  "labelItem": "NO_BUTTON",
                  "labelVisit": null,
                  "orderByField": "",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du décès",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "fieldName": "Date du deces"
                    },
                    {
                      "label": "Spécifier cause si connue",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "Vasculite",
                        "infectieux",
                        "néoplasie",
                        "neurologique",
                        "cardiovasculaire",
                        "embolie pulmonaire",
                        "digestif",
                        "insuffisance rénale",
                        "hypertension",
                        "hémorragie",
                        "respiratoire",
                        "cause iatrogène"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Specifier cause si connue"
                    },
                    {
                      "label": "Hospitalisé",
                      "type": "boolean",
                      "unit": "",
                      "choice": "",
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Hospitalise"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "i",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "82",
      "label": "Examens paracliniques",
      "index": 338,
      "children": [
        {
          "id": "83",
          "label": "Examens de laboratoire",
          "index": 338,
          "children": [
            {
              "id": "84",
              "label": "Hématologie",
              "index": 338,
              "children": [
                {
                  "id": "33800",
                  "index": 338,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Hématologie",
                  "name": "Globules blancs totaux",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "10exp9 / litre",
                      "choice": [
                        "moins de 1,5",
                        "1,6 à 5",
                        "5,1 à 10",
                        "plus de 10"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "33900",
                  "index": 339,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Hématologie",
                  "name": "Éosinophiles",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "10exp9 / litre",
                      "choice": [
                        "Moins de 0,5",
                        "0,6 à 1,5",
                        "plus de 1,5"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34000",
                  "index": 340,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Hématologie",
                  "name": "Polymorphonucléaires neutrophiles",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "10exp9 / litre",
                      "choice": [
                        "moins de 0,5",
                        "0,6 à 1,0",
                        "1,1 à 1,5",
                        "plus de 1,5"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34100",
                  "index": 341,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Hématologie",
                  "name": "Lymphocytes",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "10exp9 / litre",
                      "choice": [
                        "moins de 0,5",
                        "0,6 à 1,0",
                        "1,1 à 1,5",
                        "plus de 1,5"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34200",
                  "index": 342,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Hématologie",
                  "name": "Hémoglobine",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "10exp12 / litre",
                      "choice": [
                        "moins de 10 g/L",
                        "10,1 à 14",
                        "plus de 14"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34300",
                  "index": 343,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Hématologie",
                  "name": "Plaquettes",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Moins de 100",
                        "plus de 100"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "85",
              "label": "Biochimie",
              "index": 344,
              "children": [
                {
                  "id": "34400",
                  "index": 344,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "AST",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "> 2 X N",
                      "choice": [
                        "N",
                        "élevés"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34500",
                  "index": 345,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "ALT",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "> 2 X N",
                      "choice": [
                        "N",
                        "élevés"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34600",
                  "index": 346,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "Créatinine",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "nombre",
                      "unit": "umol/L",
                      "choice": "",
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34700",
                  "index": 347,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "Taux filtration glomérulaire estimé",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "cc/min",
                      "choice": [
                        "moins de 15",
                        "16 à 30",
                        "31 à 60",
                        "61 à 90",
                        "plus de 90"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34800",
                  "index": 348,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "CRP",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "mg/L",
                      "choice": [
                        "Moins de 3",
                        "4 à 10",
                        "plus de 10"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "34900",
                  "index": 349,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "Vitesse de sédimentation",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "mm/heure",
                      "choice": [
                        "Moins de 25",
                        "26 à 50",
                        "plus de 50"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35000",
                  "index": 350,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "HbA1C",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Moins de 6",
                        "6.1 - 7",
                        "7.1 - 8",
                        "Plus de 8"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35100",
                  "index": 351,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Biochimie",
                  "name": "Protéinurie (bâtonnet ou ratio P :C)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "g / L",
                      "choice": [
                        "< 0.3",
                        "0.31 - 0.5",
                        "0.51 - 0.99",
                        "≥ 1.0"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            },
            {
              "id": "86",
              "label": "Sérologies",
              "index": 352,
              "children": [
                {
                  "id": "35200",
                  "index": 352,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Sérologies",
                  "name": "Cryoglobulines",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "présent",
                        "absent"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35300",
                  "index": 353,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Sérologies",
                  "name": "ANCA (p ou c)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "p",
                        "c",
                        "absent"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35400",
                  "index": 354,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Sérologies",
                  "name": "Anti PR3 (présent ou absent)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "présent",
                        "absent"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35500",
                  "index": 355,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Sérologies",
                  "name": "Anti MPO (présent ou absent)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "présent",
                        "absent"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35600",
                  "index": 356,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Sérologies",
                  "name": "ANA",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "négatif",
                        "1:80 ou moins",
                        "plus de 1:80"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35700",
                  "index": 357,
                  "tab": "Examens paracliniques",
                  "category": "Examens de laboratoire",
                  "subCategory": "Sérologies",
                  "name": "Anticorps antiphospholipides",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Résultat",
                      "type": "choix_multiples",
                      "unit": "",
                      "choice": [
                        "Négatif",
                        "faible titre",
                        "titre moyen ou élevé"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Resultat"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": "87",
          "label": "Imagerie diagnostique",
          "index": 358,
          "children": [
            {
              "id": "88",
              "label": "Imagerie diagnostique",
              "index": 358,
              "children": [
                {
                  "id": "35800",
                  "index": 358,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Radiographie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "35900",
                  "index": 359,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Echographie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36000",
                  "index": 360,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Tomographie axiale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36100",
                  "index": 361,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Angioscan",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36200",
                  "index": 362,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Résonance magnétique (IRM)",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36300",
                  "index": 363,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Angio IRM",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36400",
                  "index": 364,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Angiographie conventionnelle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36500",
                  "index": 365,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "Scintigraphie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36600",
                  "index": 366,
                  "tab": "Examens paracliniques",
                  "category": "Imagerie diagnostique",
                  "subCategory": "Imagerie diagnostique",
                  "name": "TEP",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Région anatomique",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "cerébral",
                        "sinus",
                        "cou",
                        "poumons",
                        "cardiaque",
                        "abdomen",
                        "pelvien",
                        "musculosquelettique",
                        "artères temporales",
                        "artère faciale",
                        "artère carotide",
                        "artère vertébrale",
                        "artère sous-clavière",
                        "artère axillaire",
                        "tronc innominé",
                        "aorte ascendante",
                        "crosse aortique",
                        "aorte descendante",
                        "aorte abdominale",
                        "artère rénale",
                        "tronc coeliaque",
                        "artère mésentérique supérieure",
                        "artère mésentérique inférieure",
                        "artère iliaque",
                        "artère fémorale",
                        "vasculaire périphérique",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Region anatomique"
                    },
                    {
                      "label": "Description",
                      "type": "choix_multiple_non_ex",
                      "unit": "Imagerie région applicable",
                      "choice": [
                        "pachyméningite",
                        "ischémie cérébrale",
                        "vasculite cérébrale",
                        "inflammation parenchyme cérébral",
                        "thrombose veineuse cérébrale",
                        "infiltrats alvéolaires",
                        "Infiltrats interstitiels",
                        "Infiltrats",
                        "nodule(s)",
                        "adénopathies",
                        "cavités",
                        "épanchement pleural",
                        "bronchiectasies",
                        "embolie pulmonaire",
                        "épanchement péricardique",
                        "cardiomyopathie",
                        "sténose vasculaire",
                        "anévrisme",
                        "dilatation",
                        "microanévrismes",
                        "dissection",
                        "Hypermétabolisme de la paroi vasculaire",
                        "masse",
                        "abcès",
                        "hématome",
                        "ischémie mésentérique",
                        "diverticulite",
                        "infarctus rénal",
                        "Fracture",
                        "Lyse",
                        "Autre"
                      ],
                      "key": "d4",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Description"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": "89",
          "label": "Résultats de la Biopsie",
          "index": 367,
          "children": [
            {
              "id": "90",
              "label": "Résultats de la Biopsie",
              "index": 367,
              "children": [
                {
                  "id": "36700",
                  "index": 367,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Peau",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36800",
                  "index": 368,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Cérébrale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "36900",
                  "index": 369,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Méninges",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37000",
                  "index": 370,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Sinus",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37100",
                  "index": 371,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Larynx",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37200",
                  "index": 372,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Nasale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37300",
                  "index": 373,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Pulmonaire",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37400",
                  "index": 374,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Cardiaque",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37500",
                  "index": 375,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Gastrique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37600",
                  "index": 376,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Colon",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37700",
                  "index": 377,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Intestin grêle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37800",
                  "index": 378,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Foie",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "37900",
                  "index": 379,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Rein",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "38000",
                  "index": 380,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Nerf périphérique",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "38100",
                  "index": 381,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Muscle",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "38200",
                  "index": 382,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Artère temporale",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                },
                {
                  "id": "38300",
                  "index": 383,
                  "tab": "Examens paracliniques",
                  "category": "Résultats de la Biopsie",
                  "subCategory": "Résultats de la Biopsie",
                  "name": "Autre",
                  "labelItem": null,
                  "labelVisit": null,
                  "orderByField": "d2",
                  "isMultiple": true,
                  "prefixForMultiple": "",
                  "noteToUser": "",
                  "additionalData": [
                    {
                      "label": "Date de visite",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d1",
                      "attach": "v",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date de visite"
                    },
                    {
                      "label": "Date du test, si différente",
                      "type": "date",
                      "unit": "",
                      "choice": "",
                      "key": "d2",
                      "attach": "i",
                      "widthIncolumns": 3,
                      "dep": "dV,error,date,>=",
                      "typeConstraint": "warnIfAbsent",
                      "defaultRule": "defaultNowRememberLast",
                      "fieldName": "Date du test, si differente"
                    },
                    {
                      "label": "Trouvaille",
                      "type": "choix_multiple_non_ex",
                      "unit": "",
                      "choice": [
                        "infectieux",
                        "néoplasie",
                        "inflammation granulomateuse",
                        "nécrose",
                        "thrombose",
                        "vasculite d’artère musculaire",
                        "vasculite d’artère élastique",
                        "capillarite",
                        "glomérulonéphrite",
                        "hémorragie",
                        "inflammation non spécifique",
                        "changements fibrocicatritiels",
                        "normale",
                        "amyloÏdose",
                        "athérosclérose",
                        "cellules géantes",
                        "immunofluorescence positive",
                        "immunofluorescence négative",
                        "autre"
                      ],
                      "key": "d3",
                      "attach": "i",
                      "widthIncolumns": 6,
                      "dep": "rule3:2",
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Trouvaille"
                    },
                    {
                      "label": "Notes",
                      "type": "text",
                      "unit": "",
                      "choice": "",
                      "key": "d9",
                      "attach": "v",
                      "widthIncolumns": 9,
                      "dep": null,
                      "typeConstraint": null,
                      "defaultRule": null,
                      "fieldName": "Notes"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}