import React from "react";
import {CloseXButton} from "./Buttons";


const Grid = ({children}) =>
    <div className="flex flex-row">
        <div className="basis-1/4"></div>
        <div className="basis-1/2">{children}</div>
        <div className="basis-1/4"></div>
    </div>

export default ({title, children, footer, marginTop, onClose}) => <>
    <div tabIndex="-1"
         className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <Grid>
            <div className="relative p-4 w-full max-h-full" style={{marginTop}}>
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        <CloseXButton onClick={onClose}/>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        {children}
                    </div>
                    <div
                        className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        {footer}
                    </div>
                </div>
            </div>
        </Grid>
    </div>
    <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
</>