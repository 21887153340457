import React, {useEffect, useState} from "react"
import Field from "./Field"
import Label from "../widgets/Label";
import Select from "../widgets/Select";



const typesVasculites = [
  {category: "Gros vaisseaux",
   types: [
     {
       name: "Takayasu"
     },
     {
       name: "Artérite à cellules géantes (artérite temporale)"
     },
   ]},
  {category: "Moyens vaisseaux",
   types: [
     {
       name: "Polyartérite noueuse (PAN)"
     },
     {
       name: "Kawasaki"
     }
   ]},
  {category: "Petits vaisseaux / ANCA",
   types: [
     {
       name: "Granulomatose avec polyangiite (GPA / Wegener)"
     },
     {
       name: "Polyartérite microscopique (PAM)"
     },
     {
       name: "Granulomatose éosinophilique avec polyangiite (GEPA)"
     }
   ]},
  {category: "Petits vaisseaux / complexes immuns",
   types: [
     {
       name: "Anti GBM (Goodpasture)"
     },
     {
       name: "Vasculite cryoglobulinémique"
     },
     {
       name: "Vasculite à IgA (purpura de Henoch Schonlein)"
     },
     {
       name: "Vasculite urticarienne hypocomplémentémique (vasculite anti C1q)"
     },
     {
       name: "Autre (Petits vaisseaux / complexes immuns)", needsAdditionalSpecs: true
     }
   ]},
  {category: "Taille variable",
   types: [
     {
       name: "Behcet"
     },
     {
       name: "Cogan"
     }
   ]},
  {category: "Vasculite isolée à un organe",
   types: [
     {
       name: "Vasculite cutanée"
     },
     {
       name: "Vasculite isolée du système nerveux central"
     },
     {
       name: "Aortite isolée"
     },
     {
       name: "Autre (Vasculite isolée à un organe)", needsAdditionalSpecs: true
     }
   ]},
  {category: "vasculite associée à une maladie systémique",
   types: [
     {
       name: "lupus érythémateux disséminé"
     },
     {
       name: "arthrite rhumatoïde"
     },
     {
       name: "sarcoïdose"
     },
     {
       name: "Autre (vasculite associée à une maladie systémique)", needsAdditionalSpecs: false
     }
   ]},
  {category: "Vasculite associée à une étiologie probable",
   types: [
     {
       name: "hépatite C"
     },
     {
       name: "hépatite B"
     },
     {
       name: "syphilis"
     },
     {
       name: "autre infection"
     },
     {
       name: "médicaments / drogues"
     },
     {
       name: "vasculite à ANCA associée à médicaments ou drogues"
     },
     {
       name: "néoplasie"
     },
     {
       name: "Autre (Vasculite associée à une étiologie probable)", needsAdditionalSpecs: true
     }
   ]}
]


export const VasculiteForm = ({}) => {

    const [categoryVasculite, setCategoryVasculite] = useState('')

    const [vasculiteDetailOptions, setVasculiteDetailOptions] = useState([])

    const [vasculiteDetail, setVasculiteDetail] = useState('')

    useEffect(() => {

        const details = typesVasculites.find(o => o.category === categoryVasculite)

        setVasculiteDetailOptions(details && details.types)
        setVasculiteDetail('')

    }, [categoryVasculite]);

    return <div className="grid grid-cols-6 gap-6">
            <Field
                fieldValues={{}}
                isEditMode={true}
                onFieldEdit={(name, value) => {
                }}
                adata={{
                    fieldName: 'date_symptomes',
                    label: 'début des symptomes',
                    type: "date"
                }}
            />
            <Field
                fieldValues={{}}
                isEditMode={true}
                onFieldEdit={(name, value) => {
                }}
                adata={{
                    fieldName: 'date_diagnostique',
                    label: 'date du diagnostique',
                    type: "date"
                }}
            />
            <div>
                <Label text={"Type de Vasculite"}/>
                <
                    Select
                    value={categoryVasculite}
                    onChange={e => setCategoryVasculite(e.target.value)}
                >
                    <option value={null}>choisir</option>
                    {
                        typesVasculites.map(
                            t =>
                                <option key={t.category}>{t.category}</option>
                        )
                    }
                </Select>
            </div>
            <div>
                <Label text={"Détails"}/>
                <
                    Select
                    value={vasculiteDetail}
                    onChange={e => setVasculiteDetail(e.target.value)}
                >
                    {
                        vasculiteDetailOptions &&
                        vasculiteDetailOptions.map(
                            t =>
                                <option key={t.name}>{t.name}</option>
                        )
                    }
                </Select>
            </div>
            <Field
                fieldValues={{}}
                onFieldEdit={(name, value) => {
                }}
                isEditMode={true}
                adata={{
                    fieldName: 'notes',
                    label: 'Notes',
                    type: "text"
                }}
            />
        </div>
}