import React, {useEffect, useState} from "react"
import {format, getUnixTime} from "date-fns"
import {obervationTypeById} from "../metaDataProcessed"
import {sort} from "radash"
import {PencilIcon} from "../widgets/CustomIcons";
import {EditObservationPopup} from "./EditObservationPopup";


function excludeA(adata) {

    if (adata.label === "Date de visite") {
        return true
    }
    return false
}


export const ObservationEditor = ({currentVisit, healthOverviewState, healthOverviewDispatch, row}) => {

    const [editedVisitId, setEditedVisitId] = useState(currentVisit.id)

    const invertedVisits = sort(row.o.visitObservations, vo => getUnixTime(vo.visit.date), true)

    useEffect(() => {

        setEditedVisitId(currentVisit.id)

    }, [`--${healthOverviewState.visits.length}`]);


    return <td
        colSpan={healthOverviewState.visits.length + 1}
        className={"px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"}
    >
        <div style={{marginLeft: `${row.depth * 2}rem`}}>
            <div style={{cursor: 'pointer'}}>
                <div
                    className="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                >
                    <h3>{row.o.label}</h3>
                    {
                        <VisitTable
                            invertedVisits={invertedVisits}
                            observationTypeId={row.o.o.id}
                            healthOverviewDispatch={healthOverviewDispatch}
                        />
                    }
                </div>
            </div>
        </div>
    </td>
}

const VisitTable = ({observationTypeId, invertedVisits, healthOverviewDispatch}) => {

    const observationType = obervationTypeById[observationTypeId]

    const adatas = observationType.additionalData.filter(a => !excludeA(a))

    const [editedVisitObservation, setEditedVisitObservation] = useState(null)

    const saveEdits = fieldValues => {

        healthOverviewDispatch({
            name: "updateObservationFields",
            visitId: editedVisitObservation.visit.id,
            observationTypeId,
            fieldValues
        })

        setEditedVisitObservation(null)
    }

    const renderTableColumnHeaders = () => adatas.map(adata =>
        <th className="px-6 py-4" key={`header-${adata.fieldName}`}>
            {adata.label}
        </th>
    )

    const renderField = (adata, value) => {
        switch (adata.type) {
            case "date":
            case "dateD":
            case "dateF":
            case "dateFF":
                return value ? format(value, "d MMM yy") : "---"
            case "nombre":
                return value
            case "text":
                return value
            case "choix_multiple_non_ex":
            case "choix_multiples":
                return value && JSON.stringify(value)
            case "choice":
                return value
        }
    }

    const renderTableRows = () => invertedVisits.map(vo => {
        return <tr
            key={`row-${vo.observationTypeId}-${vo.visit.id}`}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >

            <td className="px-6 py-4">
                {format(vo.visit.date, "d MMM yy")}
            </td>
            <td className="px-6 py-4">
                <PencilIcon onClick={() => setEditedVisitObservation(vo)}/>
            </td>

            {
                adatas.map(adata =>
                <td className="px-6 py-4" key={`adata-${adata.fieldName}-${vo.observationTypeId}-${vo.visit.id}`}>
                    {
                        vo.observation ?
                            renderField(adata, vo.observation.fieldValues[adata.fieldName]) :
                            "---"
                    }
                </td>
            )
            }
        </tr>

    })

    return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {
            editedVisitObservation &&
            <EditObservationPopup
                title={observationType.name}
                observation={editedVisitObservation.observation}
                vo={editedVisitObservation}
                adatas={adatas}
                onSaveClick={fieldValues => saveEdits(fieldValues)}
                onCancelClick={() => setEditedVisitObservation(null)}
            />
        }
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th>Date de Visite</th>
                <th></th>
                {renderTableColumnHeaders()}
            </tr>
            </thead>
            <tbody>
            {renderTableRows()}
            </tbody>
        </table>
    </div>
}


