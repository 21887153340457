

import {objectify} from "radash";
import questionnaire from "./metaData";

export const buildObervationTypeById = () => {

    function* trav(children, depth) {
        for (const row of children) {
            if (depth < 3) {
                yield* trav(row.children, depth + 1)
            } else {
                yield row
            }
        }
    }

    return objectify([...trav(questionnaire.children, 0)], r => r.id)
}


export const obervationTypeById = buildObervationTypeById()
