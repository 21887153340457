import React from "react";

export default ({path, onClick}) =>
    <svg className="w-6 h-6 text-gray-800 dark:text-white"
         aria-hidden="true"
         xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         fill="none"
         viewBox="0 0 24 24"
         onClick={onClick}
    >
        <path
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
            d={path}
        />
    </svg>
