import React from 'react'
import ReactDOM from 'react-dom/client'
import "./style.css"

import {MainScreen} from "./components/MainScreen";



const rootElement = document.getElementById('root')

if (!rootElement) throw new Error('Failed to find the root element')




ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
        <MainScreen/>
    </React.StrictMode>
)

