import React, {useState} from "react";
import Field from "./Field";
import ModalPopup from "../widgets/ModalPopup";
import {ActiveButton, DefaultButton} from "../widgets/Buttons";


export const EditObservationPopup = ({vo, observation, title, onSaveClick, onCancelClick, adatas}) => {


    const [fieldValues, setFieldValues] = useState(observation ? observation.fieldValues: {})

    const renderFieldList = () => adatas.map(adata =>
        <Field
            key={`adata-${adata.fieldName}-${vo.observationTypeId}-${vo.visit.id}`}
            fieldValues={fieldValues}
            adata={adata}
            onFieldEdit={(name, value) => setFieldValues({
                ...fieldValues,
                [name]: value
            })}
        />
    )

    return <ModalPopup
        marginTop={200}
        title={title}
        onClose={onCancelClick}
        footer={<>
            <ActiveButton
                caption={"Save"}
                onClick={() => onSaveClick(fieldValues)}
            />
            <DefaultButton
                caption={"Cancel"}
                onClick={onCancelClick}
            />
        </>}
    >
        {
            renderFieldList()
        }

    </ModalPopup>
}