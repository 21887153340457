import React from "react";


export default ({text, disabled}) =>

    <label
        className={
            disabled ?
                "block mb-3 text-sm font-medium text-gray-900/50 dark:text-white" :
                "block mb-3 text-sm font-medium text-gray-900/100 dark:text-white"
        }
    >
        {text}
    </label>