import React from "react";
import {Datepicker} from "flowbite-react";
import Label from "../widgets/Label";
import Select from "../widgets/Select";
import TextArea from "../widgets/TextArea";
import Input from "../widgets/Input";



export default ({fieldValues, adata, onFieldEdit}) => {

    const renderWidget = () => {
        switch (adata.type) {
            case "date":
            case "dateD":
            case "dateF":
            case "dateFF":
                return <Datepicker onChange={value => {
                    onFieldEdit(adata.fieldName, value)
                }}/>
            case "nombre":
                return <Input
                    type="number"
                    onChange={e => {
                        onFieldEdit(adata.fieldName, e.target.value)
                    }}
                />
            case "text":
                return <TextArea
                    rows={2}
                    value={fieldValues[adata.fieldName]}
                    onChange={e => {
                        onFieldEdit(adata.fieldName, e.target.value)
                    }}
                />
            case "choix_multiple_non_ex":
                return <Select
                    isMultiple={true}
                    onChange={e => onFieldEdit(adata.fieldName, e.target.value)}
                >
                    {
                        adata.choice.map(c => <option key={c} value={c}>{c}</option>)
                    }
                </Select>
            case "choix_multiples":
            case "choice":
                return <Select
                    onChange={e => {
                        onFieldEdit(adata.fieldName, e.target.value)
                    }}
                >
                    {
                        adata.choice.map(c => <option key={c} value={c}>{c}</option>)
                    }
                </Select>

        }
    }

    return <div>
        <Label text={adata.label}/>
        {renderWidget()}
    </div>
}
