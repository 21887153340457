import React from "react";


export default ({caption, onClick, style}) =>
    <a
        href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        onClick={onClick}
        style={style}
    >
        {caption}
    </a>
