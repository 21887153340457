import React, {useEffect, useReducer, useState} from "react";
import NavBar from "../widgets/NavBar";
import {sort} from "radash";
import {Tab, Tabs} from "../widgets/Tabs";
import questionnaire from "../metaData";
import {VasculiteForm} from "./CustomForms";
import {QuestionaireTable} from "./QuestionaireTable";
import {healthOverviewReducer} from "./QuestionnaireTableReducer";
import Card from "../widgets/Card";
import {format, getUnixTime} from "date-fns";
import Link from "../widgets/Link";
import {fa} from "@faker-js/faker";
import ModalPopup from "../widgets/ModalPopup";
import {ActiveButton, DefaultButton, SmallButton} from "../widgets/Buttons";

const sectionsQuestionnaire = [
    questionnaire.children[0],
    questionnaire.children[1],
    questionnaire.children[2],
    questionnaire.children[3],
    questionnaire.children[4],
    questionnaire.children[5],
    questionnaire.children[6]
]


export const MainScreen = ({}) => {

    const [selectedSection, setSelectedSection] = useState(sectionsQuestionnaire[0].label)

    const [healthOverviewState, healthOverviewDispatch] = useReducer(
        healthOverviewReducer, healthOverviewReducer({}, {name: "init"})
    )

    useEffect(() => {
        healthOverviewDispatch({
            name: "setVisibleSection",
            visibleSectionLabel: selectedSection
        })
    }, [selectedSection])

    const [currentVisit, ...otherVisits] = sort(
        healthOverviewState.visits,
        visit => getUnixTime(visit.date),
        true
    )

    return <>
        <NavBar>
        <Card>
                <VasculiteForm/>
                <div>
                    <button
                        type="button"
                        className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => healthOverviewDispatch({name: "newVisit"})}
                    >
                        Nouvelle Visite
                    </button>
                    <span style={{marginLeft: 15}}>Visite courrante :</span>
                    <Link caption={format(currentVisit.date, "d MMM yyyy")}/>
                    <span style={{marginLeft: 15}}>Visites Précédentes :</span>
                    {
                        otherVisits.map(visit =>
                            <Link
                                key={`v-${visit.id}`}
                                caption={format(visit.date, "d MMM yyyy")}
                                style={{marginLeft: 20}}
                            />)
                    }
                </div>
            </Card>
            <Tabs>
                {
                    sectionsQuestionnaire.map(
                        section =>
                            <Tab
                                className="me-2"
                                key={section.id}
                                label={section.label}
                                isSelected={selectedSection === section.label}
                                onClick={() => setSelectedSection(section.label)}
                            />
                    )
                }
            </Tabs>
        </NavBar>
        <div style={{marginTop: 290}}></div>
        <div className="flex flex-row">
            <div className={"basis-1/5"}></div>
            <div className={"basis-3/5"}>
                <QuestionaireTable
                    currentVisit={currentVisit}
                    healthOverviewState={healthOverviewState}
                    healthOverviewDispatch={healthOverviewDispatch}
                />
            </div>
            <div className={"basis-1/5"}></div>
        </div>
    </>
}